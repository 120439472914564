@font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter/Inter-VariableFont_opsz,wght.ttf") format("truetype");
    font-weight: 100 900;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter/Inter-Italic-VariableFont_opsz,wght.ttf") format("truetype");
    font-weight: 100 900;
    font-style: italic;
}

.material-symbols-filled {
    font-variation-settings: 'FILL' 1;
    font-family: 'Material Symbols Outlined', sans-serif;
}

.material-symbols-filled,
.material-symbols-outlined,
.material-symbols-rounded,
.material-symbols-sharp {
    vertical-align: middle;
    font-size: 1em;
}